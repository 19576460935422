import { render, staticRenderFns } from "./OffersTab.vue?vue&type=template&id=82636d4e&scoped=true&"
import script from "./OffersTab.vue?vue&type=script&lang=js&"
export * from "./OffersTab.vue?vue&type=script&lang=js&"
import style0 from "./OffersTab.vue?vue&type=style&index=0&id=82636d4e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../Users/kcger/AppData/Roaming/npm/node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "82636d4e",
  null
  
)

export default component.exports